import { EndurmenntunEnvironment } from './types';

export const environment: EndurmenntunEnvironment = {
  mode: "staging",

  basepath: 'https://endurmenntun-service-endurmenntun-staging.apps.ocp2.stratus.is/',

  prismic: {
    endpoint: '/api/prismic',
  },

  eduframe: {
    endpoint: 'https://endurmenntunhi.eduframe.nl/',

    label: {
      color: {
        namsbrautir: "#87ceaa",
        namskeid: "",
      },
    },

    variant: {
      custom: {
        signup_expire_key: "fela-skra-mig-saekja-um-hnapp-a-vefsidu", // Valid only for endurmenntunhi eduframe instance
      },
    },
  },
};
